.card-outer {
  container-type: inline-size;
}

.card-container {
  flex-direction: column;
  height: 549px;

  .card-img {
    width: 100%;
    height: 300px;
  }

  @container (min-width: 500px) {
    .card-img {
      width: 100%;
      height: 100%;
    }

    .card-content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: fit-content !important;
      padding-top: 3rem !important;
      transition: all 0.5s ease !important;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 100%) 0%,
        rgba(0, 0, 0, 50%) 70%,
        rgba(255, 255, 255, 0%) 100%
      ) !important;

      &:hover {
        padding-top: 4rem !important;
      }

      a {
        font-size: 1.2rem !important;
        line-height: 1.8rem !important;
        color: var(--color-background) !important;
      }

      span {
        color: var(--color-background) !important;
      }

      .card-title {
        height: 86px;
      }

      .card-desc {
        display: none !important;
      }
    }
  }

  @container (min-width: 1000px) {
    .card-content {
      a {
        font-size: 1.7rem !important;
        line-height: 2.2rem !important;
      }

      .card-title {
        height: 104px;
      }
    }
  }
}

.font-landing-page {
  * {
    font-family: inherit;
  }
}

.gradient-text {
  background-image: linear-gradient(60deg, #0bea7a 10%, var(--color-background) 98%);
  color: transparent;
  background-clip: text;
  width: fit-content;
}

.heading-animation {
  -webkit-text-fill-color: transparent;
  animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: bg-mark;
  animation-name: bg-mark;
  -webkit-background-clip: text;
  animation-timing-function: ease-in-out;
  background-color: var(--color-background);
  background-image: linear-gradient(20deg, #0bea7a 60%, var(--color-background) 78%);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 300%;
}

@media screen and (max-width: 600px) {
  .heading-animation {
    background-image: linear-gradient(60deg, #0bea7a 60%, var(--color-background) 78%);
  }
}

@keyframes bg-mark {
  0% {
    background-position: right -25% top 0;
  }

  100% {
    background-position: right 20% top 0;
  }
}
